ChatComponent {
  position: fixed;
  bottom: 20px;
  right: 30px;
}

.sc-chat-window {
  z-index: 2;
}

BottomNavigation {
  bottom: 0;
}

.MuiInputLabel-outlined {
  z-index: 0 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}